<template>
  <!--滚动库存展示-->
  <div class="headdiv">
    <div style="width: 99.7%">
      <div id="alldiv2">
        <div style="width: 15%;border-right:3px solid #F00;height: 50px">
          所属工区
        </div>
        <div style="width: 15%;border-right:3px solid #F00">
          材料名称
        </div>
        <div style="width: 35%;border-right:3px solid #F00">
          规格型号
        </div>
        <div style="width: 35%;">
          库存
        </div>
      </div>
    </div>
    <div>
      <vue3-seamless-scroll :list="list" class="scroll" :hoverStop=stopFalg step="0.5">
        <div class="item" v-for="(item, index) in list" :key="index">
          <div id="alldiv">
            <div style="width: 15%;border-right:3px solid #F00;height: 50px">
              {{ item.bdst }}
            </div>
            <div style="width: 15%;border-right:3px solid #F00">
              {{ item.materialName }}
            </div>
            <div style="width: 35%;border-right:3px solid #F00">
              {{ item.materialModel }}
            </div>
            <div style="width: 35%;">
              {{ item.materialNum }} {{ item.materialUnit }}
            </div>
          </div>
        </div>
      </vue3-seamless-scroll>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {Vue3SeamlessScroll} from "vue3-seamless-scroll";
import {useRouter} from "vue-router";

export default {
  name: "material_num_show",

  components: {
    Vue3SeamlessScroll
  },
  setup() {
    // let route = useRouter();
    let list = JSON.parse(sessionStorage.getItem('material_data'));
    return {list}
  },
  data(){
    return{
      stopFalg: true
    }
  },
  created() {

  },
  methods:{

  }
}
</script>

<style scoped>


.scroll {
  height: 1024px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto 0px auto;
}

.scroll .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 0;
}

#alldiv {
  width: 100%;
  display: flex;
  border-top: 3px solid #F00;
  border-left: 3px solid #F00;
  border-right: 3px solid #F00;
  border-bottom: 3px solid #F00;
  color: red;
  text-align: center;
  line-height: 50px;
  font-size: 25px;
  font-weight: bolder;
}

#alldiv2 {
  width: 100%;
  display: flex;
  border-top: 3px solid #F00;
  border-left: 3px solid #F00;
  border-right: 3px solid #F00;
  border-bottom: 3px solid #F00;
  color: red;
  text-align: center;
  line-height: 50px;
  font-size: 25px;
  font-weight: bolder;
}

.headdiv {
  background-color: black;
  height: 30%;
  width: 100%;
  overflow: hidden
}
</style>